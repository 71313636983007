import { template as template_789777adbc5e4128b8b4dbcdb594eb06 } from "@ember/template-compiler";
const FKLabel = template_789777adbc5e4128b8b4dbcdb594eb06(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
