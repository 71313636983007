import { template as template_95b9a6e2dd8b4c68b795db442050b0cd } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { service } from "@ember/service";
export default class RenderGlimmerContainer extends Component {
    @service
    renderGlimmer;
    static{
        template_95b9a6e2dd8b4c68b795db442050b0cd(`
    {{~#each this.renderGlimmer._registrations as |info|~}}
      {{~#in-element info.element insertBefore=null~}}
        <info.component
          @data={{info.data}}
          @setWrapperElementAttrs={{info.setWrapperElementAttrs}}
        />
      {{~/in-element~}}
    {{~/each~}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
