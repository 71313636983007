import { template as template_0c12160c4e5c443b9e3cc7ffeb37d0d6 } from "@ember/template-compiler";
import dirSpan from "discourse/helpers/dir-span";
import i18n from "discourse-common/helpers/i18n";
const TopicExcerpt = template_0c12160c4e5c443b9e3cc7ffeb37d0d6(`
  {{#if @topic.hasExcerpt}}
    <a href={{@topic.url}} class="topic-excerpt">
      {{dirSpan @topic.escapedExcerpt htmlSafe="true"}}

      {{#if @topic.excerptTruncated}}
        <span class="topic-excerpt-more">{{i18n "read_more"}}</span>
      {{/if}}
    </a>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default TopicExcerpt;
